import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<div className="temp02_inner">
						<div className="content-container">
							<h2 className={"large bold"}>温度計の世界を見てみよう</h2>

							<div>
								<img
									className="temp03_image"
									src="/asset/wchart01.gif"
									width="500"
									hight="500"
									alt="温度計の歴史 古代から1700年まで"
								/>
							</div>

							<div className="flex-container">
								<div className="temp03_infrared">
									<img
										className="temp03_image2"
										src="/asset/wchart02.gif"
										width="300"
										hight="300"
										alt="温度計の歴史 1800年まで"
									/>
								</div>
								<div className="temp03_flex2">
									<ul className="temp03_ul_flex">
										<li className="temp03_flex1">
											<p className="medium triangle triangle_color_orange"></p>
										</li>
										<li className="temp03_flex2">
											<p className="medium temp02_history_name">
												熱放射(赤外線)を利用した温度計
											</p>
											<p>
												測定物体から放射されている赤外線の量で温度測定ができるため非接触測定が可能。熱放射を利用するので、測定できる領域が広範囲にわたっている。
											</p>
										</li>
									</ul>

									<ul className="temp03_ul_flex">
										<li className="temp03_flex1">
											<p className="medium triangle triangle_color_yellow"></p>
										</li>
										<li className="temp03_flex2">
											<p className="medium temp02_history_name">
												熱電気を利用した温度計
											</p>
											<p>
												ちがう種類の金属を電気的に接触し両端に温度差を与えると熱起電力によって電流が流れる。この熱電気現象（ゼーベック効果）を利用。鉄鋼、金属、原子力発電、石油精製などの分野で使用されている。
											</p>
										</li>
									</ul>

									<ul className="temp03_ul_flex">
										<li className="temp03_flex1">
											<p className="medium triangle triangle_color_yellow"></p>
										</li>
										<li className="temp03_flex2">
											<p className="medium temp02_history_name">
												電気抵抗を利用した温度計
											</p>
											<p>
												温度変化にほぼ比例して金属の電気抵抗が変化する性質を利用。測温部を構成する抵抗体の抵抗値を測定して温度を知る。ほかに、サーミスタ温度計がある。
											</p>
										</li>
									</ul>

									<ul className="temp03_ul_flex">
										<li className="temp03_flex1">
											<p className="medium triangle triangle_color_green"></p>
										</li>
										<li className="temp03_flex2">
											<p className="medium temp02_history_name">
												液体の膨張を利用した温度計
											</p>
											<p>
												液体が温度変化により膨張、収縮する性質を利用。歴史的に最も古くから知られている温度計で、構造が簡単なうえ安価なことから現在でも寒暖計、体温計などに広く応用されている。
											</p>
										</li>
									</ul>

									<ul className="temp03_ul_flex">
										<li className="temp03_flex1">
											<p className="medium triangle triangle_color_green"></p>
										</li>
										<li className="temp03_flex2">
											<p className="medium temp02_history_name">
												圧力を利用した温度計
											</p>
											<p>
												温度計内部の圧力変化により受圧部のブルドン管が変化する現象を利用。簡単な構造でしかも丈夫であり、電気を使わないので安全性が高く、プロセス計装用に多く使用されている。
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<PagingController current={3} next={'/support/documents/about-temperature/temp04'} prev={'/support/documents/about-temperature/temp02'} />
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
